@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
/* Using id to make these styles more specific and overide bootstrap. */
#navbar .nav-link {
    color: rgb(255, 250, 241);
    font-family: "Roboto";
    font-size: 20px;
}

#navbar .nav-link:hover{
    color:#c5462c
}

#navbar .dropdown-menu  {
    background-color:rgba(41, 34, 32, 1);
    border: 2px solid rgb(255, 250, 241, 1);
    border-radius: 7px;
}

#navbar .dropdown-item{
    font-family: "Roboto";
    font-size: 18px;
    color:  rgb(255, 250, 241, 1);
}

#navbar .dropdown-item:hover{
    color: #008c6a;
    background-color: unset;
}

#navbar .dropdown-item:focus{
    color: #008c6a;
    background-color: unset;
}
#navbar .dropdown-item:active{
    color: #008c6a;
    background-color: unset;
}

#navbar .navbar-toggler{
    color: rgb(255, 250, 241, 1);
    background-color: unset;
}
#navbar .navbar-toggler:hover{
    color: #008c6a;
    background-color: unset;
}

#navbar .navbar-toggler:focus{
    color: #008c6a;
    background-color: unset;
}
#navbar .navbar-toggler:active{
    color: #008c6a;
    background-color: unset;
}




.wrapper{
}

.case-study__hero-wrapper{
    margin-top: 30px;
    position: relative;
    display: flex; 
    justify-content: center;
    background-color:  rgb(41, 34, 32);
}

    
.case-study__hero-container{
    margin-top: 30px;
    max-width: 1100px;
    margin-left: 40px;
    margin-right: 40px;
}

.case-study__hero-container h1{
    font-family: 'Playfair Display', serif;
    color: rgb(255, 250, 241, 1);
}

.case-study__hero-container p{
    color: rgb(255, 250, 241, .7);
}

.hero-image{
  border-radius: 7px;
}
.sections-wrapper{
    /* padding-left: 110px; */
    background-color: #fffaf1;
    display: flex; 
    justify-content: center;
    position: relative;
    display: flex; 
}

.sections-container{
    margin-top: 30px;
    max-width: 1100px;
    margin-left: 40px;
    margin-right: 40px;
}

.title{
    color: #c5462c;
    font-weight: 700;
    font-family: "Roboto";
}

.paragraph{
    font-size: 20px;
    color: rgba(41, 34, 32, .9);
}

.heading{
    color: rgba(41, 34, 32, 1);
    font-weight: 600;
    font-family:'Playfair Display', serif;
    /* font-size: 40px; */
}

.subheading{
    color: rgba(41, 34, 32, .9);
    font-weight: 600;
    font-family: "Roboto";
}

.image-100{
    border-radius: 7px;
    width: 100%; 
}

.image-80{
    width: 80%; 
    border-radius: 7px;
}

.heuristics{
    width: 100%;
    border-left: 3px solid #c5462c;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.24) 0px 1px 3px;
}

/* sm devices and down */
@media (max-width: 767px) {
    .paragraph{
        font-size: 16px;
    }

    .case-study__hero-container{
        margin-left: 15px;
        margin-right: 15px;
    }
    .sections-container{
        margin-left: 15px;
        margin-right: 15px;
    }

}
.technology__button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding:5px; 
    text-decoration: none;
    border-radius: 7px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, .5);
}

.technology__button-container div{
    color: rgb(41, 34, 32);
}

.technology__container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.technology__image{
    height: 45px;
    padding: 3px;
    padding-right: 5p
}

.technology__title{
    color: rgb(255, 250, 241, .8);
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;
    padding-right: 2px;
}

.about-me__wrapper{
    background-color: #fffaf1;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 51px);
}

.about-me__container{
    max-width: 1100px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.about-me__heading{
    color:  rgb(41, 34, 32);
    font-weight: 700;
    font-family: 'Playfair Display', serif;
    margin-bottom: 5px;
}

.about-me__container p{
    font-size: 20px;
    margin-bottom: 20px;
    color: rgba(41, 34, 32, .8);
    margin-right: 10%; 
}

.about-me__image{
    border-radius: 7px;
    width: 90%;
    margin-bottom: 20px;
}

/* sm devices and down */
@media (max-width: 767px) {
    .about-me__container p{
        font-size: 16px;
    }
}
@keyframes drop {
    0% {
      transform: translateY(-1000%); /* Start the element off-screen */
    }
    50% {
      transform: translateY(0); /* End the element on-screen */
    }
    65% {
      transform: translateY(-8px); /* Add a small bounce */
    }
    100% {
      transform: translateY(0); /* End the element on-screen */
    }
  }

.projects__container{
   
    position: relative;
    width: 200px;
    /* animation: drop 2s ease-in-out; 
    animation-delay: 1s; */

}

.projects__title{
    position: absolute;
    top: -60px;
    font-size: 30px;
    font-weight: 600;
    color: #fffaf1;

}

.projects__container ul{
    padding: 3px;
}

.projects__container a{
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: rgba(255, 250, 241, .8);
    text-decoration: none;
    padding: 3px;

}

.projects__links-decor{
    position: absolute;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .projects__container{
    transform: scale(0.8, 0.8);
    /* animation: fadeIn 1.5s linear; 
    animation-delay: 1s; */
  }
}

@media (max-width: 575px) {
    .projects__container{
      transform: scale(0.7, 0.7);
      /* animation: none;
      animation: fadeIn 1.5s linear; 
      animation-delay: 1s; */
  }
}
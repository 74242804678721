@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Using id to make these styles more specific and overide bootstrap. */
#navbar .nav-link {
    color: rgb(255, 250, 241);
    font-family: "Roboto";
    font-size: 20px;
}

#navbar .nav-link:hover{
    color:#c5462c
}

#navbar .dropdown-menu  {
    background-color:rgba(41, 34, 32, 1);
    border: 2px solid rgb(255, 250, 241, 1);
    border-radius: 7px;
}

#navbar .dropdown-item{
    font-family: "Roboto";
    font-size: 18px;
    color:  rgb(255, 250, 241, 1);
}

#navbar .dropdown-item:hover{
    color: #008c6a;
    background-color: unset;
}

#navbar .dropdown-item:focus{
    color: #008c6a;
    background-color: unset;
}
#navbar .dropdown-item:active{
    color: #008c6a;
    background-color: unset;
}

#navbar .navbar-toggler{
    color: rgb(255, 250, 241, 1);
    background-color: unset;
}
#navbar .navbar-toggler:hover{
    color: #008c6a;
    background-color: unset;
}

#navbar .navbar-toggler:focus{
    color: #008c6a;
    background-color: unset;
}
#navbar .navbar-toggler:active{
    color: #008c6a;
    background-color: unset;
}




.links__wrapper{
  height: 100%;
  position: absolute;
  right:0px;

}
.landing__links{
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
    text-align: center;
    padding-bottom: 40px;
}

.landing__links a{
    display: block;
    color: #fffaf1;
    padding: 7px;
    margin-right: 25px;
}

.landing__links a svg{
  font-size: 35px;
}

.links__wrapper-nav{
  position: static;
  right:0px;

}
.landing__links-nav{
    text-align: center;
    padding-bottom: 0px;
}

.landing__links-nav a{
    display: inline-block;
    color: #fffaf1;
    padding: 8px;
    /* margin-right: 25px; */
}

.landing__links-nav a svg{
  font-size: 35px;
}

@media (max-width: 575px) {
    .landing__links a{
      padding: 2px;
      -webkit-transform: scale(0.7, 0.7);
              transform: scale(0.7, 0.7);
      margin-right: 5px;
  }

    .landing__links{
      top: 7px;
      text-align: center;
      padding-bottom: 7px;
  }
    
}
.brand__container{
    margin-right: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed;
    top: 35px;
    left: 35px; */
    z-index: 200;
    
    background-color: rgba(41, 34, 32, 1);
    border-radius: 100%;
    border:  2px solid rgba(255, 250, 241, 1);
}

.brand__container svg{
    margin-bottom: 3px;
    fill: rgba(255, 250, 241, 1);
    padding-top: 2px;
    display: inline-block;
    height: 40px;
    width: 40px;
}

.brand__container svg:hover{
    fill: #9b4acf;
}

.brand__container:active{
    outline:  2px solid #9b4acf;
    border:  2px solid #9b4acf;
}
.about-me__wrapper{
    background-color: #fffaf1;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 51px);
}

.about-me__container{
    max-width: 1100px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.about-me__heading{
    color:  rgb(41, 34, 32);
    font-weight: 700;
    font-family: 'Playfair Display', serif;
    margin-bottom: 5px;
}

.about-me__container p{
    font-size: 20px;
    margin-bottom: 20px;
    color: rgba(41, 34, 32, .8);
    margin-right: 10%; 
}

.about-me__image{
    border-radius: 7px;
    width: 90%;
    margin-bottom: 20px;
}

/* sm devices and down */
@media (max-width: 767px) {
    .about-me__container p{
        font-size: 16px;
    }
}
.landing__container{
    position: relative;
    height: 100vh;
    display: flex;
    /* overflow-x: hidden; */
    justify-content: center;
    background-color: rgb(41, 34, 32);
}

.landing__column-art{
    width: 13%;
    min-width: 80px;
    height: 100%;
    overflow: hidden;
}
.landing__column-welcome{
    width: 87%;
    height: 100%;
    
}

.landing__column-projects{
    position: absolute;
    bottom: 20%;
    right: 10%;
}

    /* sm devices and down */
  @media (max-width: 767px) {
    .landing__column-art{
        display: none;
    }
    .landing__column-welcome{
        width: 100%;
        height: 100%;
        margin-top: 150px;
    }
}


.welcome__container{
    height: 100%;
}

.welcome__intro-container{
    display: inline-block;
    position: -webkit-sticky;
    position: sticky;
    top: 30vh;
    padding: 20px;
    padding-bottom: 40px;
}

.welcome__heading{
    font-family: 'Playfair Display', serif;
    font-size: 40px; 
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 40px;
    color: #fffaf1;
}

.welcome__tagline{
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 250, 241, .6);
}

@media (max-width: 991px) {
    .welcome__intro-container{
        margin: 5px;
        margin-top: 30px;
    }
    .welcome__tagline{
        font-size: 15px;
    }
    .welcome__heading{
        font-size: 32px; 
        line-height: 32px;
    }    
}

@media (max-width: 767px) {
    .welcome__intro-container{
        display: inline-block;
        position: static;
        margin: 20px;
    }
}

@media (max-width: 575px) {
    .welcome__intro-container{
        margin: 5px;
        margin-top: 30px;
    }
    .welcome__tagline{
        font-size: 13px;
    }
    .welcome__heading{
        font-size: 30px; 
        line-height: 30px;
    }    
}
.art-squares__container{
  height: 100%;
  width: 100%;
  margin-top: -50px;
  cursor: url(/static/media/paintbrush.4022be88.cur), pointer;
}
.art-square__container{
    display: block;
    font-size: 100px;
    line-height: 80px;
    text-align: center;
    margin: -5px;
}

.art-square__square{

}

@-webkit-keyframes drop {
    0% {
      -webkit-transform: translateY(-1000%);
              transform: translateY(-1000%); /* Start the element off-screen */
    }
    50% {
      -webkit-transform: translateY(0);
              transform: translateY(0); /* End the element on-screen */
    }
    65% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); /* Add a small bounce */
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0); /* End the element on-screen */
    }
  }

@keyframes drop {
    0% {
      -webkit-transform: translateY(-1000%);
              transform: translateY(-1000%); /* Start the element off-screen */
    }
    50% {
      -webkit-transform: translateY(0);
              transform: translateY(0); /* End the element on-screen */
    }
    65% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); /* Add a small bounce */
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0); /* End the element on-screen */
    }
  }

.projects__container{
   
    position: relative;
    width: 200px;
    /* animation: drop 2s ease-in-out; 
    animation-delay: 1s; */

}

.projects__title{
    position: absolute;
    top: -60px;
    font-size: 30px;
    font-weight: 600;
    color: #fffaf1;

}

.projects__container ul{
    padding: 3px;
}

.projects__container a{
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: rgba(255, 250, 241, .8);
    text-decoration: none;
    padding: 3px;

}

.projects__links-decor{
    position: absolute;
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .projects__container{
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
    /* animation: fadeIn 1.5s linear; 
    animation-delay: 1s; */
  }
}

@media (max-width: 575px) {
    .projects__container{
      -webkit-transform: scale(0.7, 0.7);
              transform: scale(0.7, 0.7);
      /* animation: none;
      animation: fadeIn 1.5s linear; 
      animation-delay: 1s; */
  }
}
.card__container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.technologies__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    margin-top: 20px;
    margin-bottom: 20px;
}
/* sm devices and down */
@media (max-width: 767px) {
    .technologies__container{
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.technology__button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding:5px; 
    text-decoration: none;
    border-radius: 7px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, .5);
}

.technology__button-container div{
    color: rgb(41, 34, 32);
}

.technology__container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.technology__image{
    height: 45px;
    padding: 3px;
    padding-right: 5p
}

.technology__title{
    color: rgb(255, 250, 241, .8);
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;
    padding-right: 2px;
}

.wrapper{
}

.case-study__hero-wrapper{
    margin-top: 30px;
    position: relative;
    display: flex; 
    justify-content: center;
    background-color:  rgb(41, 34, 32);
}

    
.case-study__hero-container{
    margin-top: 30px;
    max-width: 1100px;
    margin-left: 40px;
    margin-right: 40px;
}

.case-study__hero-container h1{
    font-family: 'Playfair Display', serif;
    color: rgb(255, 250, 241, 1);
}

.case-study__hero-container p{
    color: rgb(255, 250, 241, .7);
}

.hero-image{
  border-radius: 7px;
}
.sections-wrapper{
    /* padding-left: 110px; */
    background-color: #fffaf1;
    display: flex; 
    justify-content: center;
    position: relative;
    display: flex; 
}

.sections-container{
    margin-top: 30px;
    max-width: 1100px;
    margin-left: 40px;
    margin-right: 40px;
}

.title{
    color: #c5462c;
    font-weight: 700;
    font-family: "Roboto";
}

.paragraph{
    font-size: 20px;
    color: rgba(41, 34, 32, .9);
}

.heading{
    color: rgba(41, 34, 32, 1);
    font-weight: 600;
    font-family:'Playfair Display', serif;
    /* font-size: 40px; */
}

.subheading{
    color: rgba(41, 34, 32, .9);
    font-weight: 600;
    font-family: "Roboto";
}

.image-100{
    border-radius: 7px;
    width: 100%; 
}

.image-80{
    width: 80%; 
    border-radius: 7px;
}

.heuristics{
    width: 100%;
    border-left: 3px solid #c5462c;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.24) 0px 1px 3px;
}

/* sm devices and down */
@media (max-width: 767px) {
    .paragraph{
        font-size: 16px;
    }

    .case-study__hero-container{
        margin-left: 15px;
        margin-right: 15px;
    }
    .sections-container{
        margin-left: 15px;
        margin-right: 15px;
    }

}

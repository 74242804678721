.links__wrapper{
  height: 100%;
  position: absolute;
  right:0px;

}
.landing__links{
    position: sticky;
    top: 30px;
    text-align: center;
    padding-bottom: 40px;
}

.landing__links a{
    display: block;
    color: #fffaf1;
    padding: 7px;
    margin-right: 25px;
}

.landing__links a svg{
  font-size: 35px;
}

.links__wrapper-nav{
  position: static;
  right:0px;

}
.landing__links-nav{
    text-align: center;
    padding-bottom: 0px;
}

.landing__links-nav a{
    display: inline-block;
    color: #fffaf1;
    padding: 8px;
    /* margin-right: 25px; */
}

.landing__links-nav a svg{
  font-size: 35px;
}

@media (max-width: 575px) {
    .landing__links a{
      padding: 2px;
      transform: scale(0.7, 0.7);
      margin-right: 5px;
  }

    .landing__links{
      top: 7px;
      text-align: center;
      padding-bottom: 7px;
  }
    
}
.landing__container{
    position: relative;
    height: 100vh;
    display: flex;
    /* overflow-x: hidden; */
    justify-content: center;
    background-color: rgb(41, 34, 32);
}

.landing__column-art{
    width: 13%;
    min-width: 80px;
    height: 100%;
    overflow: hidden;
}
.landing__column-welcome{
    width: 87%;
    height: 100%;
    
}

.landing__column-projects{
    position: absolute;
    bottom: 20%;
    right: 10%;
}

    /* sm devices and down */
  @media (max-width: 767px) {
    .landing__column-art{
        display: none;
    }
    .landing__column-welcome{
        width: 100%;
        height: 100%;
        margin-top: 150px;
    }
}


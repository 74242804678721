.technologies__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    margin-top: 20px;
    margin-bottom: 20px;
}
/* sm devices and down */
@media (max-width: 767px) {
    .technologies__container{
        transform: scale(0.8);
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
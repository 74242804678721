.art-square__container{
    display: block;
    font-size: 100px;
    line-height: 80px;
    text-align: center;
    margin: -5px;
}

.art-square__square{

}

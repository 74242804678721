.welcome__container{
    height: 100%;
}

.welcome__intro-container{
    display: inline-block;
    position: sticky;
    top: 30vh;
    padding: 20px;
    padding-bottom: 40px;
}

.welcome__heading{
    font-family: 'Playfair Display', serif;
    font-size: 40px; 
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 40px;
    color: #fffaf1;
}

.welcome__tagline{
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 250, 241, .6);
}

@media (max-width: 991px) {
    .welcome__intro-container{
        margin: 5px;
        margin-top: 30px;
    }
    .welcome__tagline{
        font-size: 15px;
    }
    .welcome__heading{
        font-size: 32px; 
        line-height: 32px;
    }    
}

@media (max-width: 767px) {
    .welcome__intro-container{
        display: inline-block;
        position: static;
        margin: 20px;
    }
}

@media (max-width: 575px) {
    .welcome__intro-container{
        margin: 5px;
        margin-top: 30px;
    }
    .welcome__tagline{
        font-size: 13px;
    }
    .welcome__heading{
        font-size: 30px; 
        line-height: 30px;
    }    
}